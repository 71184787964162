import { SwiperProps } from "swiper/react";

import CONSTANTS from "config/constants";
const { wide, desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

export const responsive: SwiperProps["breakpoints"] = {
  0: {
    slidesPerView: 2,
    spaceBetween: 8
  },
  [mobile]: {
    slidesPerView: 3,
    spaceBetween: 8
  },
  [tablet]: {
    slidesPerView: 3,
    spaceBetween: 8
  },
  [desktop]: {
    slidesPerView: 4,
    spaceBetween: 8
  },
  [wide]: {
    slidesPerView: 5,
    spaceBetween: 8
  }
};
