import styled from "styled-components";

import { RecommendedSnacksListStyledProps as Props } from "./RecommendedSnacksList.types";

const RecommendedSnacksListStyled = styled.div<Props>`
  .RecommendedSnacksList {
    &__wrapper {
      position: relative;
      height: 100%;
      border-radius: 0.8rem;
      overflow: hidden;
    }
    &__image {
      background-color: var(--palette-light-blue-s10-l60);
      width: 18rem;
      height: 18rem;
    }
    &__title {
      color: var(--palette-light-blue-s10-l60);
      text-align: start;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 1.6rem 0.8rem;
    }

    &__info {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0.8rem;
      background-color: var(--palette-gray-s0-l20-a70);
    }

    &__text {
      color: var(--palette-white);
      font-size: 1.4rem;
      font-weight: 600;

      &--price {
        color: var(--palette-primary);
      }
    }

    &__button {
      position: absolute;
      top: 0;
      right: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 0.4rem;
      margin: 0.8rem;
      padding: 0;
      background-color: var(--palette-primary);

      path {
        fill: var(--palette-white);
      }

      &:disabled {
        border: solid 0.3rem;
        background-color: transparent;
        box-shadow: var(--palette-primary-light) 0 0 0 0.02rem;

        path {
          fill: var(--palette-white);
        }
      }

      &:hover:not([disabled]) {
        background-color: var(--palette-mid-blue-s15-l20);
        box-shadow: none;
        border: solid 0.2rem var(--palette-primary);
      }
    }

    &__icon {
      color: var(--palette-white);
    }
  }

  .Slider {
    width: 100%;
  }
`;

export default RecommendedSnacksListStyled;
