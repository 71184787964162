import { useShoppingCartStore } from "@multicines/stores";
import { getApiMessageError } from "@multicines/utils";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import HeartSVG from "images/black-heart.svg";
import CartSVG from "images/cart.svg";
import FilledHeartSVG from "images/heart-primary-filled.svg";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { useCallback, useEffect } from "react";

import Styles from "./CartButton.styles";
import { CartButtonProps as Props } from "./CartButton.types";
import ShoppingCartDrawer from "components/global/drawer/ShoppingCartDrawer/ShoppingCartDrawer";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import usePendingOrder from "hooks/usePendingOrder";
import { useAuthStore } from "stores/auth/auth.store";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";

const { Item } = TabsMenuUI;
const { ARTISN, FEATURE_FLAGS } = CONSTANTS;
const { WITH_CART_DRAWER } = FEATURE_FLAGS;
const { WITH_ANONYMOUS, WITH_PURCHASE } = FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME } = ARTISN;
const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const CartButton: React.FC<Props> = props => {
  const { className, product } = props;
  const [showNotification, setShowNotification] = useState(false);
  const { pathname, push } = useRouter();
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const { validatePendingOrder } = usePendingOrder();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const [opened, setOpened] = useState(false);
  const [disable, setDisable] = useState(false);

  const isProductDetails = pathname === "/products/[productId]/[productName]";
  const filledStyle = products?.length ? "CartButton__filled" : "";
  const disabledStyle = !products?.length ? "CartButton__disabled" : "";

  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const icon = inCart ? FilledHeartSVG : HeartSVG;

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  const addProductHandler = async () => {
    if (!product || disable) return;
    setDisable(true);
    await addToWishlist();
    if (!inCart) setShowNotification(true);
    setDisable(false);
  };

  const cartHandler = async () => {
    if (!WITH_ANONYMOUS && isAnonymous) {
      push("/signin");
      return;
    }

    const onContinue = () => {
      if (WITH_CART_DRAWER) {
        setOpened(prev => !prev);
        dismissAddToCartNotification();
      } else {
        push("/cart");
      }
    };

    const onError = (error: string) => {
      createErrorNotification(
        error,
        undefined,
        dismissErrorNotification,
        1000 * 30
      );
    };

    try {
      await validatePendingOrder(onContinue, onError);
    } catch (e) {
      notify(e, "cartHandler - CartButton");
      const message = getApiMessageError(e);
      createErrorNotification(message);
    }
  };

  const heartNode = (
    <Item
      icon={icon}
      className="CartButton--heart"
      onClick={addProductHandler}
    />
  );

  const cartNode = (
    <Item
      icon={CartSVG}
      className={`CartButton--cart ${filledStyle} ${disabledStyle}`}
      onClick={cartHandler}
    />
  );

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`CartButton ${className}`}
      isProductDetails={isProductDetails}
      isSignedIn={!isAnonymous}
    >
      {heartNode}
      {cartNode}
      {WITH_CART_DRAWER && WITH_PURCHASE ? (
        <ShoppingCartDrawer opened={opened} onClose={cartHandler} />
      ) : null}
    </Styles>
  );
};

CartButton.defaultProps = {
  className: ""
};

export default CartButton;
