import { getApiMessageError } from "@multicines/utils";
import { getProductTotals } from "artisn/products";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { defaultConfig } from "./AddToCartButton.helpers";
import { useAddToCart } from "./AddToCartButton.hooks";
import Styles from "./AddToCartButton.styles";
import { AddToCartButtonProps as Props } from "./AddToCartButton.types";
import Button from "components/global/Button/Button";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import usePendingOrder from "hooks/usePendingOrder";
import useUtils from "hooks/useUtils";
import { useAuthStore } from "stores/auth/auth.store";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { WITH_ANONYMOUS } = CONSTANTS.FEATURE_FLAGS;

const AddToCartButton: React.FC<Props> = props => {
  const { className, disabled, form, config = defaultConfig } = props;
  const { shouldReplace, onClick, isAdding } = useAddToCart({ ...props });
  const { formatByCurrency } = useUtils();
  const { push } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const { product, status } = form ?? {};
  const totals = product ? getProductTotals(product, config.amount) : undefined;
  const { netPrice } = totals ?? {};
  const noOKClass = status !== "OK" ? "AddToCartButton__btn__no-ok" : "";
  const t = useI18n();
  const { validatePendingOrder } = usePendingOrder();
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = async () => {
    setIsLoading(true);
    if (!WITH_ANONYMOUS && isAnonymous) {
      push("/signin");
      setIsLoading(false);
      return;
    }

    const onContinue = async () => {
      await onClick();
      setIsLoading(false);
    };

    const onError = (error: string) => {
      createErrorNotification(
        error,
        undefined,
        dismissErrorNotification,
        1000 * 30
      );
      setIsLoading(false);
    };

    try {
      await validatePendingOrder(onContinue, onError);
    } catch (error) {
      notify(error, "clickHandler - AddToCartButton");
      setIsLoading(false);
      const message = getApiMessageError(error);
      createErrorNotification(message);
    }
  };

  return (
    <Styles className={`AddToCartButton ${className}`}>
      <Button
        mode="PRIMARY"
        className={`${noOKClass}`}
        onClick={clickHandler}
        disabled={disabled || !product?.available || isAdding || isLoading}
        isLoading={isAdding || isLoading}
      >
        <p className="AddToCartButton__text">
          {shouldReplace ? (
            t.common.continue
          ) : (
            <>
              <span className="AddToCartButton__text--desktop">
                {t.cart.add}
              </span>
              <span className="AddToCartButton__text--mobile">
                {t.common.add}
              </span>
            </>
          )}
        </p>
        <p className="AddToCartButton__text">{formatByCurrency(netPrice)}</p>
      </Button>
    </Styles>
  );
};

AddToCartButton.defaultProps = {
  className: ""
};

export default AddToCartButton;
